import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Images
import Icon from 'img/logo_pointing_left.inline.svg'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import GravityForm from 'components/GravityForm'
import CustomBreadCrumb from 'components/Breadcrumb'

// Third Party
import styled from 'styled-components'

const StyledImg = styled(Img)`
  z-index: -1;
  pointer-events: none;
  height: 100%;
`

const ImageContainerText = styled.div`
  margin-top: -100px;
  color: ${props => props.theme.color.text.light};

  @media screen and (max-width: 991px) {
    margin-top: -60px;
  }
`

const ImgContainer = styled.div`
  height: 330px;

  @media screen and (max-width: 767px) {
    height: 230px;
  }

  @media screen and (max-width: 483px) {
    height: 170px;
  }
`

const ContactFormWrapper = styled.div`
  z-index: 10;
  max-width: 880px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);

  .form-group__checkboxes {
    display: inline-block;
    width: 50%;

    label {
      display: inline !important;
    }
  }

  label {
    display: none;
  }

  .form-submit {
    position: absolute;
    bottom: 50px;
    right: -250px;
    background-color: ${props => props.theme.color.contrast};
    border-radius: 5px;
    color: ${props => props.theme.color.secondary};
    height: 45px;
    width: 200px;

    @media screen and (max-width: 991px) {
      position: relative;
      bottom: 0;
      right: 0;
      width: 100%;
    }
  }
`

const ContactWrapper = styled.div`
@media screen and (max-width: 991px) {
  .form {
    &-group {
      width: 100%;
    }

    &-select {
      width: 100%;
    }
  }
}
`

const StyledIcon = styled(Icon)`
  position: absolute;
  left: 380px;
  bottom: 93px;
  height: 200px;
  width: 176px;

  @media screen and (max-width: 991px) {
    display: none;
  }
`

const ContactContext = styled.div`
  pointer-events: none;
`

const Contact = ({ pageContext }) => {
  const { 
    contact: {
      title: pageTitle,
      path,
      acf: {
        banner_image: {
          localFile: {
            childImageSharp: {
              fluid: bannerImage
            }
          }
        }
      }
    },
    yoastMeta
  } = useStaticQuery(graphql`
    {
      contact: wordpressPage(wordpress_id: {eq: 142}) {
        title
        path
        acf {
          banner_image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        yoastMeta: yoast_meta {
          name
          content
          property
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} />
      <ContactWrapper>
        <ImgContainer>
          <StyledImg className="d-flex" alt="" fluid={bannerImage} />
          <ImageContainerText className="container">
            <div className="title font-size-xxl font-weight-xl">
              <h2 className="color-text-light font-weight-xl">Contact</h2>
            </div>
          </ImageContainerText>
        </ImgContainer>
        <CustomBreadCrumb data={pageTitle} className="py-2" pageContext={pageContext} />
        <div className="container pt-lg-5 pt-3 pb-lg-0 pb-5 d-flex justify-content-center">
          <ContactFormWrapper className="px-3 px-sm-5 py-4">
            <h2 className="font-size-l mb-4 font-weight-xl color-text-contrast">Neem contact met ons op!</h2>
            <div className="row mx-0">
              <div className="col-lg-5 position-relative p-0">
                <GravityForm id={1} kuufOnButton />
              </div>
              <ContactContext className="col-lg-7 pt-5 pl-lg-5 color-text-main">
                <p className="font-size-m">Toch nog een vraag? Geen probleem. Stuur ons een berichtje. Want als we in het water liggen, kunnen we de telefoon natuurlijk niet opnemen. </p>
                <p className="font-size-m">Vind je het fijn als we jou bellen? Laat dan meteen even weten wanneer we jou het beste kunnen bereiken.</p>
              </ContactContext>
            </div>
          </ContactFormWrapper>
        </div>
        <div className="d-lg-block d-none empty-space-300" />
      </ContactWrapper>
    </Layout>
  )
}

export default Contact